<template>
    <div>
    <h1>Crypto conector </h1>
    <button
      class="btn btn-primary btn-margin"
      @click="connectWallet()">
      connectWallet
    </button>
    Cuenta de Ethereum :  {{ eth_address }}
    Balance en la cuenta : {{ balance }}
    </div>
</template>

<script>
import Web3 from 'web3'
export default {
  name: 'crptoConector',
  data () {
    return {
      msg: 'Welcome to crypto Appp',
      eth_address: 'No conectado',
      balance: 'No conectado'
    }
  },
  methods: {
    connectWallet () {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum)
        try {
          // Request account access if needed
          // window.ethereum.enable()
          const accounts = window.ethereum.request({ method: 'eth_requestAccounts' })
          accounts.then(function (data) { window.eth_address = data[0] })
          this.eth_address = window.eth_address
          const testnet = 'https://ropsten.infura.io/'
          const walletAddress = this.eth_address
          const web3 = new Web3(new Web3.providers.HttpProvider(testnet))
          //var balance = web3.eth.getBalance(walletAddress)
          //console.log(balance)
        } catch (error) {
          // User denied account access...
        }
      } else { // Non-dapp browsers...
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!')
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>